<template>
  <div id="app">
    <!-- <img alt="Vue logo" class='header' src="./assets/logo.png"> -->
    <!-- Сверху постоянная панель с экспортом docx. -->
    <b-navbar class="pl-0 pr-0" variant="faded" type="light">
    <b-navbar-brand href="#">{{appName}}</b-navbar-brand>
    <b-button-toolbar aria-label="Toolbar with button groups and dropdown menu">
    <!-- <b-button-group class="mx-1">
      <b-button variant="success">Новый</b-button>
      <b-button variant="outline-success">Отменить</b-button>
    </b-button-group> -->

    <b-button-group class="mx-1" align="right">
      <!-- <b-button variant="primary">Сохранить</b-button> -->
      <b-button variant="outline-primary" @click='$refs.repGenerator.exportDocx();'>
        <b-icon icon="file-word-fill"></b-icon>Экспорт в Word
      </b-button>
      <!-- <b-button variant="outline-secondary" @click="$refs.repGenerator.test()">Test</b-button> -->

    </b-button-group>
  </b-button-toolbar>
  </b-navbar>
    <div>
  
</div>
    <ReportGenerator ref="repGenerator" title="Генератор отчётов"/>
  </div>
</template>

<script>
import ReportGenerator from './components/ReportGenerator.vue'

export default {
  name: 'App',
  components: {
    ReportGenerator,
  },
  data() {
    return {
      appName:"Генератор отчётов"      
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
  margin: 5em 2rem;
  /* margin-top: 60px; */
}
.header {
  text-align: center;
}
</style>
