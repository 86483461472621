<template>
  <div class="page">
    <b-form>
      <!-- label="Скопируйте поля с Предпохода" -->
      <b-form-group
        id="input-group-1"
        label-for="input-1"
        description="We'll never share your email with anyone else."
      >
        <label for="start-datepicker">Дата начала похода</label> {{dates.start}}
        <b-form-datepicker id="start-datepicker" v-model="dates.start" placeholder="Выберите дату" locale="ru"
          class="mb-2"></b-form-datepicker>

        <!-- Нитка отчёта. После вставки текста должна предложить разбиение по дням и создать секции. Позволить перетащить и довставлять объекты внутрь. -->
        <h6 @click="show=!show" class=''>Примеры ниток </h6>
        <b-alert :show="show" dismissible>
          <b>Нить маршрута</b>
          <p>с. Привольное – пер. Форточка (1240, н/к) – [Ночёвка] – вер. Эклизи-Бурун (1527, н/к) – пер. Ангарский
            (752, н/к) – [Ночёвка] – вер. Северная Демерджи (1359, н/к) – т/с Восточный Саул - вер. Такья-Тепе (1253,
            н/к) - вер. Кенгечь-Оба (1120, н/к) - пер. Большие Ворота (1010, н/к) – род. Тарасовский - пер. Алакат-Богаз
            (730, н/к) - пер. Кок-Асан-Богаз (580, н/к) - т/с Верхний Кок-Асан - с. Поворотное - пер. Нижний Шелен (663,
            н/к) - вер. Айваз-Кая (872, н/к) - т/с Ворон - т/с Березки - вер. 880 (880, н/к) - вер. Куркушлу-Оба (878,
            н/к) - т/с Эски-Юрт - с. Лесное</p>
          <p>Пос. Бол. Голоустное - Падь Ушканья - Падь Нижняя - мыс Толстый - Падь Марьяная - падь Пыловка – Половинная
            – Шабартуй - падь Тёмная - пос. Култук</p>
        </b-alert>

        <div class='mb-2'><b-button variant="primary" @click="insertNight" :draggable="true" @dragstart="draggedData = this.NIGHTSTRING">
          Ночёвка</b-button>
        Расставьте Ночёвки по нитке.</div>
        <b-form-textarea 
         ref="routeString"
         id="textarea"
        v-model= "routeString" 
        placeholder="Нитка отчёта"
        rows="3"
        max-rows="6"
       ></b-form-textarea> 
        <!-- @input="onEditRouteString" -->

        <!-- {{routeArr}}, {{ dates}} -->
        <p class='mb-0 mt-2'>Проверьте разбиение по дням:</p>
         <b-form-tags input-id="tags-basic" 
         remove-on-delete
         tag-variant="primary"
         tag-pills
         no-add-on-enter
         disabled
         v-model="routeArr" placeholder=""></b-form-tags>
        <template v-if="routeString.length">
          <DaySection ref="dayRef" v-for="(el,i) in routeArr" :key="i" :number="i+1" 
          :date="dayDate(dates.start, i)" 
          :route="el"
          :summary="test"></DaySection>
        </template>

      </b-form-group>
    </b-form>
  </div>
</template>

<script>
import { Document, HeadingLevel, Packer, Paragraph, Table, TableCell, TableRow, WidthType, TextRun, VerticalAlign, AlignmentType } from "docx";
import { saveAs } from 'file-saver';

import DaySection from '@/components/DaySection.vue'
const NIGHTSTRING = "– [Ночёвка] –"
const DASH = "—"

export default {
  name: 'ReportGenerator',
  components: {
    DaySection,
  },
  watch:  {
    routeString: function (val) {
     this.routeString = val.replace(/ - /g, ` ${DASH} `)
    },
  },
  computed: {
    // DaySectionArr: function () {
    //       <DaySection v-for="(el,i) in routeArr" :key="i" :number="i+1" :date="dayDate(dates.start, i)" :route="el"></DaySection>

    //   return [
    //     routeArr,
    //     index
    //   ]
    // },
    routeArr: function () {
      return this.routeString.split(NIGHTSTRING)
      // .map(el => {
      //   el = el.trim()
      //   if (el.startsWith(DASH)) {el = el.slice(el.indexOf(DASH)+DASH.length)} 
      //   if (el.endsWith(DASH)) {el = el.slice(el.lastIndexOf(DASH)+DASH.length); console.log('end',el)} 
      //   el = el.trim() 
      //   console.log('el',el)
      // });// || []
      // TODO: trim spaces and dashes 
    },
    // DaySectionArr: function () {
    //   return this.routeArr.map((el,i) => {
    //     let number=i+1
    //     let day={
    //       index: number,
    //       date: this.dayDate(this.dates.start, i),
    //       route: el,
    //       weather: this.$refs.dayRef[number].weather,
    //       goal: "",
    //       logbook: "",
    //       summary:""
    //     }
    //     return day
    //   });
    // }
  },
  data() {
    return {
      datesString:null,
      urlPredpohod:null,
      text: null, 
      show: true,
      routeString:"",//"с. Привольное – пер. Форточка (1240, н/к) – [Ночёвка] – вер. Эклизи-Бурун (1527, н/к) – пер. Ангарский (752, н/к) – [Ночёвка] – вер. Северная Демерджи (1359, н/к) – т/с Восточный Саул – вер. Такья-Тепе (1253, н/к) – вер. Кенгечь-Оба (1120, н/к) – пер. Большие Ворота (1010, н/к) – род. Тарасовский  – [Ночёвка] – – пер. Алакат-Богаз (730, н/к)   –  пер. Кок-Асан-Богаз (580, н/к) – т/с Верхний Кок-Асан  – [Ночёвка] –  с. Поворотное – пер. Нижний Шелен (663, н/к)  – [Ночёвка] –  вер. Айваз-Кая (872, н/к) – т/с Ворон  – [Ночёвка] –  т/с Березки – вер. 880 (880, н/к) – вер. Куркушлу-Оба (878, н/к)  – [Ночёвка] –  т/с Эски-Юрт – с. Лесное",
      NIGHTSTRING: NIGHTSTRING,
      dates:{
        depart:null,
        start:null,
        finish:null,
        arrive:null,
        total: null,
        active: null
      },
      // DaySectionArr:[{
      //   index: 1,
      //   date:'',
      //   route: 'this.routeString[1]',
      //   weather: "",
      //   goal: "",
      //   logbook: "",
      //   summary:""
      // }]
    }
  },
  methods: {
    test(){
      console.log("-------\nTEST")
      console.log(this.$refs.dayRef)
      console.log("ENDED\n------")
    },
    insertNight(){
      const start = this.$refs.routeString.selectionStart
      const insertStr = NIGHTSTRING
      this.routeString = this.routeString.slice(0, start) + insertStr + this.routeString.slice(start)
      console.log('LOG', start, "\n", "Start "+this.dates.start +"\n", insertStr, "\n", this.routeString)
    },
    dayDate(base, add) {
      let res = base? new Date(base) : new Date()
      res.setDate(res.getDate() + add)
      console.log("EL2", res)
      return res
    },
    exportDocx(){
      // console.log("Day", day)
      // console.log("DaySectionArr", this.DaySectionArr)
    const content={
    sections: [
        {
            children: [
                new Paragraph({
                    text: "ТЕХНИЧЕСКОЕ ОПИСАНИЕ",
                    heading: HeadingLevel.HEADING_2,
                }),
                new Paragraph({
                    text:
                        "Здесь и далее, если не указано иное:",
                }),
                new Paragraph({
                text: "все направления и берега рек в тексте даны орографически.",
                bullet: {level: 0}
                }),
                new Paragraph({
                    text: "используются сокращения С, Ю, З, В и их комбинации – стороны света.",
                    bullet: {level: 0}
                }),
                new Paragraph({
                    text: "приведённые временные интервалы – чистое ходовое время (ЧХВ).",
                    bullet: {level: 0}
                }),
            
                new Paragraph({
                    text: "Фотографии выполнены _______________________",
                }),
                new Paragraph({}), new Paragraph({}),
            ],
        },
    ],
    }
    if (!this.$refs.dayRef) alert("Заполните нитку маршрута и расставьте ночёвки")
    this.$refs.dayRef.forEach((day) => {
        content.sections[0].children.push(
          new Table({
              rows: [
                  new TableRow({
                      children: [
                          new TableCell({
                              width: {
                                  size: 60,
                                  type: WidthType.PERCENTAGE,
                              },
                              children: [new Paragraph({ text: "Маршрут", 
                                      alignment: AlignmentType.CENTER,
                              })],
                              verticalAlign: VerticalAlign.CENTER,
                          }),
                          new TableCell({
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                   size: 20,
                                  type: WidthType.PERCENTAGE,
                              },
                              children: [new Paragraph({  
                                        children: [
                                        new TextRun({
                                                  text: "Погода",
                                                // font: "Calibri",
                                                // allCaps: true,
                                            })],
                                        alignment: AlignmentType.CENTER,
                                      })],
                          }),
                            new TableCell({
                              width: {
                                  size: 5505,
                                  type: WidthType.DXA,
                              },
                              children: [new Paragraph({ text: "Дата", alignment: AlignmentType.CENTER, }),],
                              verticalAlign: VerticalAlign.CENTER,
                          }),
                          new TableCell({
                            width: {
                                  size: 2505,
                                  type: WidthType.DXA,
                              },
                              children: [new Paragraph({ text: "День\nпохода", alignment: AlignmentType.CENTER, }),],
                              verticalAlign: VerticalAlign.CENTER,
                              
                          }),
                      ],
                  }),
                  new TableRow({
                      children: [
                          new TableCell({
                              verticalAlign: VerticalAlign.CENTER,
                              children: [
                                
                                  new Paragraph({
                                      children:
                                          [new TextRun({
                                                  text: day.route,
                                                bold: true,
                                            })],
                                      // heading: HeadingLevel.HEADING_3,
                                      alignment: AlignmentType.CENTER,
                                  }),
                              ],
                          }),
                          new TableCell({
                              children: [
                                  new Paragraph({
                                      children:
                                          [new TextRun({
                                                  text: day.weather,
                                                bold: true,
                                            })],
                                      alignment: AlignmentType.CENTER,
                                  }),
                              ],
                              verticalAlign: VerticalAlign.CENTER,
                          }),
                          new TableCell({
                              children: [
                                  new Paragraph({
                                      children:
                                          [new TextRun({
                                                  text: day.date.toISOString().split('T')[0],
                                                bold: true,
                                            })],
                                      alignment: AlignmentType.CENTER,
                                  }),
                              ],
                              verticalAlign: VerticalAlign.CENTER,
                          }),
                          new TableCell({
                              children: [
                                  new Paragraph({
                                      children:
                                          [new TextRun({
                                                  text: day.number,
                                                bold: true,
                                            })],
                                      alignment: AlignmentType.CENTER,
                                  }),
                              ],
                              verticalAlign: VerticalAlign.CENTER,
                          }),
                      ],
                  }),
              ],
          }),
          new Paragraph({
            text: day.goal,
            }),
          new Paragraph({
                children:
                    [new TextRun({
                            text: day.logbook,
                          // bold: true,
                      })],
                // alignment: AlignmentType.CENTER,
            }),
          
          new Paragraph({
            children:
                [new TextRun({
                        text: "Выводы",
                      bold: true,
                  }), new TextRun({
                        text: " "+day.summary,
                  })],
              alignment: AlignmentType.LEFT,
            }), 
            new Paragraph({}) //конец добавляемого в каждый день
        ) // конец пуша   
        content.sections[0].children.push(
          new Paragraph({
                text: "все направления и берега рек в тексте даны орографически.",
                bullet: {level: 0}
                }), 
        )  
    }); //конец экспорта
/*  TODO дизайн. Заголовок. Отступы в таблице. Таймс12ж, Таймс9ж, Таймс12, Залипание связности данных,  
    Последний пункт стоянки должен повторяться в следующем дне
    Погода с картинками*/
        console.log(content)

        const doc = new Document(content)
        Packer.toBlob(doc).then((blob) => {
            // saveAs from FileSaver will download the file
            saveAs(blob, `report-${Date.now()}.docx`);
        });

    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.badge, .tags-basic, .b-form-tag-content {color: black !important;}

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
