<template>
  <div class="page">
    <h3>День {{ number }}. {{beautyDate}}</h3>
    <b>Нитка дня</b>: {{route}}
    <b-form-select :ref="'weather'+number" placeholder="Укажите погоду дня" v-model="weather" :options="[{ value: null, text: 'Укажите погоду дня', disabled: true }, { value: 'Ясно', text: 'Ясно' }, { value: 'Снег', text: 'Снег' },{ value: 'Дождь', text: 'Дождь' },]" 
    class="mt-3"></b-form-select>
    
    <b-form-textarea
          id="textarea"
          :ref="'goal'+number"
          rows="3"
          @input="goalEdit"
          v-model="goal"
        ></b-form-textarea>
      <!-- v-model=""  -->
    <b-form-textarea
      id="textarea"
      placeholder="Техописание: разбейте день на промежуточные этапы длительностью не более 4 ч"
      rows="3"
      :ref="'logbook'+number"
      v-model="logbook"
    ></b-form-textarea>
    <b-form-textarea
      id="textarea"
      placeholder="Выводы и предложения"
      rows="3"
      :ref="'summary'+number"
      v-model="summary"
    ></b-form-textarea>
   
  </div>
</template>

<script>
export default {
  name: 'DaySection',
  props: {
    number: Number,
    date: Date,   
    route: String,
  },
  computed: {
    
  },
  data() {
    return {
      weather:null,
      logbook:null,
      goal:"Цель ходового дня: ",
      beautyDate: this.date.toISOString().split('T')[0],
      summary: "",
    }
  },
  methods: {
    goalEdit(){
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
